<template>
	<div class="wrapper">
		<section class="booking-request-section">
			<div class="container">
				<div class="booking-request">
					<tk-button @click="goBack" kind="back-button">
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						Назад
					</tk-button>

					<h2 class="section-title">Запрос бронирования</h2>

					<div class="booking-request__content">
            <PriceSidebar
							:additional-amenities="amenities"
							:price="place.price"
							:placeId="placeId"
              :room-type="place.room_type"
							:rooms="place.rooms"
							:reviewsCount="place.reviews_count"
							:rating="place.rating"
							is-booking
						/>

						<div class="booking-request__container">
							<!-- <div class="is-business-trip">
							<p class="is-business-trip__text">Это деловая поездка?</p>
							<div class="switcher">
								<input
									class="tgl tgl-light"
									id="business_trip"
									name="business_trip"
									type="checkbox"
								/>
								<label class="tgl-btn" for="business_trip"></label>
							</div>
						</div>
						<form action="#" class="booking-request__form" id="payment_method">
							<h2 class="section-title">Оплатить с помощью</h2>
							<el-select
								class="select"
								v-model="payMethodValue"
								popper-class="select__options-dropdown"
								:popper-append-to-body="false"
								placeholder="Выберите способ оплаты"
							>
								<el-option
									class="select__option"
									v-for="item in payMethodOptionsoptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
									<i :class="item.iconClass"></i>
									<span> {{ item.label }}</span>
								</el-option>
							</el-select>
							<div class="card">
								<label for="card-number">Номер карты</label>
								<input type="text" id="card-number" autocomplete="off" />
								<div class="label-wrapper">
									<label for="card-to-month">Срок действия</label>
									<label for="card-cvc">СVC</label>
								</div>
								<div class="wrap">
									<div class="period-wrapper">
										<input type="text" id="card-to-month" autocomplete="off" />
										<span class="separator">/</span>
										<input type="text" id="card-to-year" autocomplete="off" />
									</div>
									<input type="text" id="card-cvc" />
								</div>
							</div>
							<fieldset class="booking-request__form_fieldset">
								<legend class="booking-request__form_legend">
									Адрес выставления счета
								</legend>
								<ul class="booking-request__form_list">
									<li class="list-item">
										<input
											type="text"
											class="booking-request__form_input"
											placeholder="Адрес"
										/>
									</li>
									<li class="list-item">
										<input
											type="text"
											class="booking-request__form_input"
											placeholder="Номер квартиры"
										/>
									</li>
									<li class="list-item">
										<input
											type="text"
											class="booking-request__form_input"
											placeholder="Город"
										/>
									</li>
									<li class="list-item">
										<input
											type="text"
											class="booking-request__form_input"
											placeholder="Субъект"
										/>
										<input
											type="text"
											class="booking-request__form_input"
											placeholder="Индекс"
										/>
									</li>
								</ul>
								<el-select
									class="select country-select"
									v-model="countryValue"
									popper-class="select__options-dropdown"
									:popper-append-to-body="false"
									placeholder="Страна/регион"
								>
									<el-option
										class="select__option"
										v-for="item in countryOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</fieldset>
						</form> -->
							<!--suppress SyntaxError -->
              <div class="cancellation-rules">
								<h2 class="section-title">Правила жилья</h2>
								<p class="cancellation-rules__rule">
                  Эти правила указал хозяин жилья. Пожалуйста, ознакомьтесь с ними перед бронированием:
									<ul>
                    <li>
                      Бесплатная отмена бронирования возможна до:
                      {{ freeCancelationDate }}
                    </li>
                    <li>
                      {{ place?.settings?.percentage_forfeit ?
                        `Неустойка за отмену после срока бесплатной отмены:
                          ${place?.settings?.percentage_forfeit}% от суммы бронирования` :
                        'Хозяин жилья не взимает неустойку за отмену бронирования.'
                      }}
                    </li>
                  </ul>
								</p>
								<!-- <div class="read-more-btn" @click="ruleOneShown = !ruleOneShown">
								{{ ruleOneShown ? 'Закрыть' : 'Подробнее' }}
							</div> -->
								<!-- <p
								class="cancellation-rules__rule"
								:class="{ shown: ruleTwoShown }"
							>
								<b>Ознакомьтесь с правилами отмены.</b> На бронирования,
								оформленные после 14 марта, не распространяется Положение о
								применении уважительных причин во время вспышки COVID-19.
							</p>
							<div class="read-more-btn" @click="ruleTwoShown = !ruleTwoShown">
								{{ ruleTwoShown ? 'Закрыть' : 'Подробнее' }}
							</div> -->
							</div>
							<div class="booking-accept-info">
								<i class="icon icon-calendar-1"></i>
								<p class="booking-accept-info__text">
									<b
										>Бронирование будет подтверждено, когда хозяин примет запрос
										(в течение 24 часов)</b
									>
									<br />
									<span>До тех пор деньги не спишутся</span>
								</p>
							</div>
							<div class="legal-text">
								Нажимая кнопку ниже, я принимаю условия следующих политик:
								<router-link :to="{ name: 'License' }">
									Правила дома, Раскрытие информации о безопасности, Правила
									отмены, Социальное дистанцирование и другие рекомендации
									Теримок во время пандемии, и Правила компенсации
									гостю</router-link
								>. Я также соглашаюсь оплатить полную сумму, включая сборы за
								услуги.
							</div>
							<tk-button
								class="booking-request__submit"
								form="payment_method"
                :disabled="inProcess"
								@click="submit"
							>
								Запросить бронирование
							</tk-button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import PriceSidebar from '@/components/PriceSidebar'

import modals from '@/constants/modals'
import { getDateInFormat } from "@/utils/dateTime";

export default {
	name: 'BookingRequest',
	components: { PriceSidebar },
	data() {
		return {
			place: {},

      inProcess: false
		}
	},
	async created() {
		const response = await this.$api.place.get({
			id: this.placeId
		})

		if (response?.status) {
			this.place = {
				...response.response
			}
		}
	},
	computed: {
		placeId() {
			return this.$route.params.slug
		},
		amenities() {
			return this.place.amenities
				?.map((itm) => itm.options)
				.flat()
				.filter((itm) => +itm.price)
		},
    freeCancelationDate() {
      let freeCancelationDate = null
      if (this.place?.settings) {
        const freeDays = this.place.settings.free_cancellation_days
        freeCancelationDate = new Date(this.$route.query.date_from);
        freeCancelationDate.setDate(freeCancelationDate.getDate() - freeDays)
      }
      return freeCancelationDate ? getDateInFormat(freeCancelationDate) : null
    }
	},
	methods: {
    goBack() {
      this.$router.push({
        path: `/place/${this.$route.params.slug}`,
        query: this.$route.query
      })
    },
		async submit() {
      this.inProcess = true

			const { bed_ids, amenities, room_id, ...payload } = this.$route.query

      payload.bed_ids = bed_ids
					? typeof bed_ids === 'string'
						? [bed_ids]
						: [...bed_ids]
					: []

      payload.amenities = amenities
					? typeof amenities === 'string'
						? [amenities]
						: [...amenities]
					: []

      if (room_id) {
        payload.room_id = room_id
      }

			const response = await this.$api.reserve.create({ ...payload, place_id: this.placeId })

			if (!response.status) {
        let error = "Ошибка"
        if (response.errors.message) {
          error = "Ошибка"
        } else if (response.errors.reserve) {
          error = response.errors.reserve
        } else if (response.errors.guests) {
          error = response.errors.guests
        }

				this.$store.commit('showNotification', {
					type: 'error',
					text: error
				})

        this.inProcess = false
        return
			}

      this.inProcess = false
			this.$store.commit('showModal', {
				name: modals.BOOKING_REQUEST_SUCCESS,
				props: null,
				isShow: true
			})
		}
	}
}
</script>

<style lang="sass" scoped>
.booking-request
  &__content
    width: 100%
    display: flex
    flex-direction: row-reverse
    flex-wrap: wrap
    justify-content: space-between

    @media screen and (max-width: 800px)
      flex-direction: row

.container
	padding: 0 100px
.section-title
	margin-bottom: 30px
	margin-top: 30px
.booking-request-section
	padding-top: 30px
	padding-bottom: 100px
.booking-request
	position: relative
	&__submit
    width: 250px
    height: 45px

	::v-deep(.price-sidebar)
		// min-height: 390px
		// position: absolute
		// right: -460px
		// &-btn
			// display: none
		// .el-date-editor
		// 	pointer-events: none
		// .el-input-number__decrease, .el-input-number__increase
		// 	display: none
		// .total__list
		// 	padding-top: 20px
	&__container
		max-width: 640px
		position: relative
		.is-business-trip
			display: flex
			justify-content: space-between
			align-items: center
			border-top: 1px solid #e2e2e2
			border-bottom: 1px solid #e2e2e2
			padding: 30px 0
			margin-bottom: 30px
			&__text
				font-size: 24px
				line-height: 24px
				font-weight: bold
				margin-bottom: 0
	&__form
		padding-bottom: 30px
		margin-bottom: 30px
		border-bottom: 1px solid #DCDEE0
		::v-deep .select
			width: 100%
			box-shadow: unset
			border: 1px solid #E2E2E2
			margin-bottom: 20px
			.el-input__inner
				height: 60px
				font-size: 14px
				line-height: 14px
			.el-input__suffix
				top: 9px
			.el-select__popper.el-popper
				top: 60px !important
		.card
			position: relative
			width: 100%
			max-width: 335px
			height: 210px
			padding: 40px 16px 20px 16px
			border-radius: 13px
			background: url("~@/assets/images/card-bg.jpg") center no-repeat
			box-shadow: 0px 0px 36px rgba(132, 223, 239, 0.43), 0px 10px 36px rgba(192, 185, 231, 0.51)
			z-index: 1
			margin-bottom: 35px
			&:before
				content: ''
				position: absolute
				z-index: 0
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: rgba(224, 224, 224, 0.43)
				border: 2px solid rgba(245, 245, 245, 0.41)
				border-radius: 13px
			label
				position: relative
				font-weight: 500
				font-size: 13px
				line-height: 16px
			#card-number, #card-cvc
				position: relative
				display: block
				background: rgba(248, 248, 248, 0.4)
				border-radius: 11px
				height: 38px
				border: none
			#card-number
				display: block
				width: 100%
				border: 1px solid #000000
				font-size: 22px
				line-height: 26px
				text-align: center
				margin-bottom: 26px
			.label-wrapper
				display: flex
				justify-content: space-between
			label[for=card-cvc]
				margin-right: 30px
			.wrap
				display: flex
				justify-content: space-between
			#card-to-month, #card-to-year, #card-cvc
				font-size: 15px
				line-height: 18px
				font-weight: bold
			.separator
				font-weight: bold
				font-size: 15px
				line-height: 18px
			.period-wrapper
				position: relative
				display: flex
				align-items: center
				width: 86px
				background: rgba(248, 248, 248, 0.4)
				border-radius: 11px
				height: 38px
			#card-to-month, #card-to-year
				width: 48%
				background: transparent
				border: none
				text-align: center
			#card-cvc
				width: 61px
				text-align: center
		&_legend
			font-weight: 600
			font-size: 16px
			line-height: 16px
			margin-bottom: 20px
		&_list
			list-style: none
			padding-left: 0
			margin-bottom: 20px
			border: 1px solid #E2E2E2
			border-radius: 12px
			.list-item
				&:not(:last-child)
					border-bottom: 1px solid #e2e2e2
				&:last-child
					display: flex
					input
						width: 50%
						&:first-child
							border-right: 1px solid #E2E2E2
		&_input
			padding: 23px 15px
			width: 100%
			border: none
			background: transparent
			font-size: 14px
			line-height: 14px
		.country-select
			margin-bottom: 0
	.cancellation-rules
		font-size: 18px
		line-height: 22px
		margin-bottom: 30px
		border-bottom: 1px solid #DCDEE0
		&__rule
			overflow: hidden
			// display: -webkit-box
			// -webkit-line-clamp: 2
			// -webkit-box-orient: vertical
			// &.shown
			// 	-webkit-line-clamp: unset
	.read-more-btn
		cursor: pointer
		color: #1AC386
		font-size: 18px
		line-height: 22px
		font-weight: 500
		margin-bottom: 30px
		&:hover
			text-decoration: underline
	.booking-accept-info
		display: flex
		padding-bottom: 30px
		margin-bottom: 30px
		border-bottom: 1px solid #DCDEE0
		font-size: 18px
		line-height: 22px
		.icon
			color: #574343
			margin-right: 15px
			font-size: 30px
		&__text
			margin: 0
			span
				color: #AFA9A7
	.legal-text
		font-size: 10px
		line-height: 12px
		margin-bottom: 30px
		a
			color: #1AC386
			&:hover
				text-decoration: underline

// 1080
@media screen and (max-width: 1440px)
	.container
		padding: 0
	.booking-request
		&__container
			max-width: 620px

// 996
@media screen and (max-width: 1180px)
	.booking-request
		&__container
			max-width: 536px
// 900
@media screen and (max-width: 1024px)
	.booking-request
		&__container
			max-width: 500px
			.price-sidebar
				right: -400px
// 768
@media screen and (max-width: 912px)
	.booking-request
		&__container
			max-width: 400px
			.price-sidebar
				right: -370px
// 540
@media screen and (max-width: 800px)
	.container
		padding: 0 20px
	.booking-request
		&__container
			max-width: 100%
			.price-sidebar
				position: static
</style>
